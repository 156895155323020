import "react-loading-skeleton/dist/skeleton.css";

import { CurrentAccountIdProvider } from "@zapier/identity";
import { GlobalStylesProvider } from "@zapier/design-system";
import { GlobalStyle } from "../lib/theme";
import UserProvider from "lib/context/user-context";
import React, { Suspense, useEffect, useState } from "react";
import { PageErrorBoundary } from "components/PageErrorBoundary";
import { DndProvider } from "lib/react-dnd";
import { MixpanelContextProvider } from "../observability/mixpanel";
import { TrackingProvider } from "observability";
import { ConfirmModalProvider } from "components/ModalConfirmation";
import { GlobalHead } from "components/GlobalHead";
import { SkeletonTheme } from "components/SkeletonTheme";
import { PublishedPageSpinner } from "block-system/components/PublishedPageSpinner";
import { SplitProvider } from "lib/context/split-context";
import { ThemeProvider, type InterfacesTheme } from "lib/theme/ThemeProvider";
import { ProjectSessionExpiredModal } from "components/SessionExpiredModal";
import { Toaster } from "@/components/Toaster";
import { BuilderFlagsProvider } from "lib/context/builder-flags-context";
import type { SplitCheck } from "utils/trpc";
import { ConsumerProject } from "@/server/schemas/projects";

type Props = {
  children: React.ReactNode;
  project: ConsumerProject;
  fallback?: React.ReactNode;
  interfacesTheme?: InterfacesTheme | null;
  builderFlags: SplitCheck;
};

export default function PublishedProvider({
  children,
  project,
  fallback = <PublishedPageSpinner />,
  interfacesTheme,
  builderFlags,
}: Props) {
  return (
    <>
      <GlobalHead />
      <GlobalStylesProvider isPlatform={true} isRebrand={true}>
        <ThemeProvider
          projectAppearance={project.appearance}
          interfacesTheme={interfacesTheme}
          allowSystemThemeMode={true}
        >
          <GlobalStyle />
          <ProjectSessionExpiredModal />
          <SkeletonTheme>
            <PageErrorBoundary>
              <Suspense fallback={fallback}>
                <CurrentAccountIdProvider>
                  <DndProvider>
                    <ConfirmModalProvider>
                      <UserProvider>
                        <TrackingProvider skipPageViewEvent>
                          <MixpanelContextProvider>
                            <SplitProvider
                              projectId={project.id}
                              fallback={fallback}
                            >
                              <BuilderFlagsProvider builderFlags={builderFlags}>
                                {children}
                              </BuilderFlagsProvider>
                            </SplitProvider>
                          </MixpanelContextProvider>
                        </TrackingProvider>
                      </UserProvider>
                    </ConfirmModalProvider>
                  </DndProvider>
                </CurrentAccountIdProvider>
              </Suspense>
            </PageErrorBoundary>
            <Toaster />
          </SkeletonTheme>
        </ThemeProvider>
      </GlobalStylesProvider>
      <E2EPublishedPageHydratedIndicator />
    </>
  );
}

/**
 * This component was introduced to help writing tests for
 * https://zapierorg.atlassian.net/browse/INTRFCS-3088 and
 * https://zapierorg.atlassian.net/browse/INTRFCS-4188.
 *
 * The idea here is to give a way for Playwright to know when page hydrated.
 *
 * With SSR, page hydration can take some time. In some cases, Playwright is fast enough
 * to start performing actions before hydration is complete. If that is the case,
 * the test might fail since events are not yet attached to the DOM elements.
 */
function E2EPublishedPageHydratedIndicator() {
  const [hydrated, setHydrated] = useState(false);

  useEffect(() => {
    setHydrated(true);
  }, []);

  if (!hydrated) {
    return null;
  }

  return <div data-testid="published-page-hydrated" aria-hidden={true} />;
}
